import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // minWidth: 288,
    maxWidth: 390,
    height: 240,
    backgroundColor: '#F8F9FB',
    marginRight: 16,
    marginTop: 16,
    borderRadius: 16,
    '&:hover': {
      boxShadow: '0px 2px 16px #CCD0D9',
    },
  },
  content: {
    padding: 25,
    justifyContent: 'space-between',
    width: '100%',
    height: '89vh',
    position: 'relative',
    // overflowX: 'hidden',
  },
  cardContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  usersNumber: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#3E4347',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  credentialsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  openLock: {
    marginRight: 5,
    maxWidth: 16,
    height: 20,
  },
  bin: {
    maxWidth: 14,
    height: 20,
  },
  credentials: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#3E4347',
    marginLeft: '0 !important',
  },
  email: {
    marginLeft: '0 !important',
  },
  //  TODO посмотреть использования и переопределить marginLeft в теме
}))

export default useStyles
