import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    stroke: 'currentColor',
    strokeWidth: 2,
    fill: 'none',
  },
})
/* eslint-disable */
export const UsersIcon: React.FC<SvgIconProps> = ({ className, ...otherProps }) => {
  const styles = useStyles()

  return (
    <SvgIcon className={classNames(styles.root, className)} {...otherProps}>
      <path d="M1 19.3077C1 15.4842 4.13401 12.3846 8 12.3846C11.866 12.3846 15 15.4842 15 19.3077M15.5 12.3846C19.366 12.3846 22.5 15.4842 22.5 19.3077M14 1.29307C14.4658 1.10452 14.9715 1 15.5 1C17.8196 1 19.7 3.01359 19.7 5.30769C19.7 7.6018 17.8196 9.61538 15.5 9.61538C14.9715 9.61538 14.4658 9.51086 14 9.32232M12.2 5.30769C12.2 7.6018 10.3196 9.61538 8 9.61538C5.6804 9.61538 3.8 7.6018 3.8 5.30769C3.8 3.01359 5.6804 1 8 1C10.3196 1 12.2 3.01359 12.2 5.30769Z" />
    </SvgIcon>
  )
}
