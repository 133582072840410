import React, { FC } from 'react'
import { BaseLayout } from 'components/baseLayout'
import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import useStyles from 'pages/users/userCreate/styles'
import { useDispatch, useSelector } from 'react-redux'
import { TPlayerUser } from 'interfaces/model/PlayerUsers'
import { TState } from 'store/store'
import { userCreateRequest } from 'store/modules/playerUsers/actions'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Routes } from 'routes/routes'
import { useHistory } from 'react-router'
import TextFieldInput from 'components/textFieldInput'

export const UserCreatePage: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { handleSubmit, control } = useForm()
  const onSubmit = (data: TPlayerUser) => {
    dispatch(userCreateRequest(data, redirectToUsers))
  }
  const clients = useSelector((state: TState) => state.users.clients)
  const history = useHistory()
  const redirectToUsers = () => {
    history.push(Routes.users)
  }
  return (
    <BaseLayout>
      <Grid container>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid container className={classes.header}>
            <Grid item>
              <ArrowBackIcon onClick={redirectToUsers} />
            </Grid>
            <Grid item className={classes.button}>
              <input type="submit" placeholder="Сохранить" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                field={{ title: 'Фамилия', maxWidth: 248 }}
                as={TextFieldInput}
                name="lastName"
                control={control}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                as={TextFieldInput}
                field={{ title: 'Имя', maxWidth: 248 }}
                name="firstName"
                control={control}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                as={TextFieldInput}
                field={{ title: 'Отчество', maxWidth: 248 }}
                name="fatherName"
                control={control}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Controller
                as={TextFieldInput}
                field={{ title: 'email', maxWidth: 240 }}
                control={control}
                name="email"
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseLayout>
  )
}
