import React, { FC } from 'react'
import styled from 'styled-components'
import { BaseLayout } from '../../../../components/baseLayout'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../../store/store'
import { CardMedia, FormControlLabel, Paper, Radio, Typography } from '@material-ui/core'
import { TPlaylist } from '../../../../store/modules/catalogs/types'
import { createStyles, makeStyles, Theme, WithStyles } from '@material-ui/core/styles'
import { MouseEvent } from 'react'
import { config } from '../../../../config/config'
import { editPlaylist } from '../../../../store/modules/catalogs/actions'

interface IProps {
  playlist: TPlaylist
  storageUrl: string
  handleOpen: (playlist: TPlaylist) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      borderRadius: theme.spacing(2),
      height: 135,
      width: 135,
      border: '1px solid #E0E0E0',
    },
    header: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    text: {
      padding: 8,
      maxWidth: '80%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    media: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
    radioBtn: {
      color: '#27AE60',
      '&$checked': {
        color: '#F5F5F5',
      },
    },
  }),
)

export const PlaylistItem = ({ playlist, storageUrl, handleOpen }: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(playlist.exposedLk)
  const isHaveImage = playlist.playlistImage ? true : false
  const preventEvent = (event: React.MouseEvent<unknown>) => {
    event.preventDefault()
    event.stopPropagation()
    setValue(!playlist.exposedLk)
    dispatch(
      editPlaylist(
        playlist.name,
        isHaveImage ? playlist.playlistImage.id : '',
        playlist.id,
        playlist.ownerId,
        playlist.type,
        !playlist.exposedLk,
      ),
    )
  }
  const handleClickOpen = () => {
    handleOpen(playlist)
  }
  const imageLink =
    playlist.playlistImage !== null ? storageUrl + '/' + playlist.playlistImage.relativePath : config.standardCover

  return (
    <Paper className={classes.content} elevation={0}>
      <CardMedia image={imageLink} title={playlist.type} className={classes.media} onClick={handleClickOpen}>
        <div className={classes.header}>
          <Typography variant={'body2'} className={classes.text}>
            {playlist.name}
          </Typography>
          <Radio checked={value} color="default" classes={{ root: classes.radioBtn }} onClick={preventEvent} />
        </div>
        <div>
          <Typography variant={'caption'} color={'textSecondary'} className={classes.text}>
            {playlist.numberOfPhonogram + ' треков'}
          </Typography>
        </div>
      </CardMedia>
    </Paper>
  )
}
