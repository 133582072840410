import React, { FC, useEffect, useRef } from 'react'
import { GlobalStyle } from 'styles/global-styles'
import { Input, InputContainer } from 'components/input'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getRegistrationSuccess, getIsLoaded, getIsLoading, getUser } from 'store/modules/registration/selectors'
import { registrationRequest } from 'store/modules/registration/actions'
import { Button } from 'pages/auth/components/button'
import { Container, Wrapper, OutlineContainer, SubTitle } from 'pages/auth/components/container'
import { ThemeProvider } from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Routes } from 'routes/routes'
import { StyledLink, styles } from 'pages/auth/styles'
import logo from 'assets/images/logo.svg'
import { userSchema } from 'constants/ValidationMessage'
import { mainTheme } from 'styles/main-theme'

export type RegistrationFormState = {
  email: string
  password: number
  password_repeat: number
  firstName: string
  isLoading: false
  type: string
  companyType: string
}

export const RegistrationPage: FC /*<Props>*/ = () => {
  const { register, errors, handleSubmit, watch } = useForm<RegistrationFormState>({
    resolver: yupResolver(userSchema),
  })
  const password = useRef({})
  // password.current = watch('password', '')

  const dispatch = useDispatch()
  const history = useHistory()
  const userSignedUp = useSelector(getRegistrationSuccess)

  const onSubmit = (data: RegistrationFormState) => {
    dispatch(registrationRequest(data))
  }

  const user = useSelector(getUser)
  const loaded = useSelector(getIsLoaded)
  const loading = useSelector(getIsLoading)

  useEffect(() => {
    if (userSignedUp) {
      history.push(Routes.auth)
    }
  }, [userSignedUp])

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle whiteColor />

      <Wrapper>
        <OutlineContainer>
          <Container>
            <div>
              <img style={styles.LogoSecondary} src={logo} alt="караоке" />
            </div>
            <div style={styles.TitleSecondary}> Объединённые караоке системы </div>
            <SubTitle> Регистрация </SubTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputContainer>
                <Input primaryText name="email" placeholder="Email" defaultValue="" ref={register()} />
              </InputContainer>
              {errors.password && <p>{errors.email?.message}</p>}
              <InputContainer>
                <Input
                  name="password"
                  placeholder="Пароль"
                  type="password"
                  defaultValue=""
                  ref={register({
                    required: 'Обязательное поле',
                    minLength: {
                      value: 8,
                      message: 'Пароль должен состоять минимум из 8 символов',
                    },
                  })}
                />
              </InputContainer>
              {errors.password && <p>{errors.password.message}</p>}

              <InputContainer>
                <Input
                  name="password_repeat"
                  placeholder="Повторите пароль"
                  type="password"
                  required
                  defaultValue=""
                  ref={register({
                    validate: (value) => value === password.current || 'Пароли не совпадают',
                  })}
                />
              </InputContainer>
              {errors.password_repeat && <p>{errors.password_repeat.message}</p>}

              <InputContainer>
                <Input name="name" placeholder="Название организации" type="string" defaultValue="" ref={register()} />
              </InputContainer>
              <InputContainer>
                <Input name="inn" placeholder="ИНН" type="string" defaultValue="" ref={register()} />
              </InputContainer>
              <InputContainer>
                <Input name="firstName" placeholder="Имя" type="string" defaultValue="" ref={register()} />
              </InputContainer>
              <p>{errors.firstName?.message}</p>
              <InputContainer>
                <Input name="lastName" placeholder="Фамилия" type="string" defaultValue="" ref={register()} />
              </InputContainer>
              <InputContainer>
                <Input name="phone" placeholder="Номер телефона" type="string" defaultValue="" ref={register()} />
              </InputContainer>
              <Button primary type="submit">
                Зарегистрироваться
              </Button>
            </form>
            <StyledLink as="a" href={Routes.auth}>
              или <span>Войти</span>
            </StyledLink>
          </Container>
        </OutlineContainer>
      </Wrapper>
    </ThemeProvider>
  )
}
