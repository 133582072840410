import { request } from 'api'
import { AppDispatch } from 'store/store'
import { deleteDeviceSuccess, updateDevices } from './reducer'

export const devicesListRequest = () => {
  return (dispatch: AppDispatch) => {
    request
      .get('/devices')
      .then(function (response) {
        const data = response.data.data
        dispatch(updateDevices(data))
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
        }
      })
  }
}

export const deleteDevice = (id: string | null) => {
  return (dispatch: AppDispatch) => {
    request.delete(`/devices/${id}`).then(function (response) {
      dispatch(deleteDeviceSuccess(id))
    })
  }
}
