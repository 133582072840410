export const COLORS = {
  MAIN_1: '#000000',
  MAIN_2: '#3E4347',
  GREY_MAIN: '#18191D',
  GREY_ADD: '#282E33',
  GREY_SECOND: '#3E4347',
  BLUE_MAIN: '#2164A9',
  STROKE_GRAD: 'linear-gradient(351.24deg, #0084D1 11.34%, #A902F6 100%)',
  BTN_MAIN: 'linear-gradient(278.24deg, #0084D1 6.32%, #4233B0 50.38%, #A902F6 94.43%)',
  BACK_MAIN: 'linear-gradient(151.53deg, #330255 1.35%, #000722 46.53%)',
  GREY_SUB: '#828282',
  ALERT: '#EB5757',
}
