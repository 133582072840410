import React from 'react'
import styled from 'styled-components'
export const Title = {
  textTransform: 'uppercase',
  fontSize: 24,
} as React.CSSProperties

const Logo = {
  maxWidth: '100%',
  height: 'auto',
}

export const StyledLink = styled.button`
  text-decoration: none;
  color: #f5f5f5;
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
  @media (max-width: 1152px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
  > span {
    text-decoration: underline;
  }
`
export const StyledArrowLink = styled(StyledLink)`
  align-self: flex-start;
  width: 25vw;
  display: flex;
  > * {
    &:first-child {
      margin-left: 15px;
    }
  }
  > * {
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
  @media (max-width: 1152px) {
    width: 39vw;
    font-size: 18px;
    line-height: 21px;
    margin-top: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
`

const LogoSecondary = {
  width: '100%',
  height: 'auto',
  maxWidth: '146px',
}

const TitleSecondary = {
  fontSize: '9px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
} as React.CSSProperties

export const styles = {
  Title: Title,
  TitleSecondary: TitleSecondary,
  Logo: Logo,
  LogoSecondary: LogoSecondary,
}
