import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { CustomTable } from 'pages/catalogs/components/table'
import { Routes } from 'routes/routes'
import { TPhonogram } from 'store/modules/catalogs/types'
import { useSelector } from 'react-redux'
import { TState } from 'store/store'
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { COLORS } from 'theme/colors/colors'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1700 },
    items: 9,
  },
  largeDesktop: {
    breakpoint: { max: 1700, min: 1400 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 1400, min: 1300 },
    items: 7,
  },
  tablet: {
    breakpoint: { max: 1300, min: 600 },
    items: 6,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 3,
  },
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titles: {
      padding: 24,
      paddingLeft: 0,
      fontWeight: 'bold',
    },
  }),
)

export const Charts = () => {
  const handleClick = () => {
    console.log('click')
  }
  const classes = useStyles()
  // const changeFilterState = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const filteredPlaylist = phonograms.filter((item: TPhonogram) => item.name.includes(event.target.value))
  //   setFilteredPlaylist(filteredPlaylist)
  // }
  const phonograms = useSelector((state: TState) => state.catalogs.phonograms)

  const [filteredPlaylist, setFilteredPlaylist] = React.useState(phonograms)

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          alignItems: 'flex-start',
          paddingTop: 17,
          paddingBottom: 17,
        }}
      >
        <Typography variant="body1" className={classes.titles}>
          {'Мир ТОП 100'}
        </Typography>
        <CustomTable tracks={filteredPlaylist} handleChoose={handleClick} isCreated={false} withChoose={false} />
      </div>
    </>
  )
}
