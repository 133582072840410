import { request } from 'api'
import { AppDispatch, TState } from 'store/store'
import { isLoading } from 'store/modules/auth/reducer'
import { loadCatalogs, loadAllPhonograms, loadImageUrl, loadTracks } from './reducer'
import { ImageFile, TPlaylist } from './types'
import { useRadioGroup } from '@material-ui/core'

export const getCatalogs = () => {
  return (dispatch: AppDispatch, getState: () => TState) => {
    request
      .get('/playlists')
      .then(function (response) {
        const playlist = response.data.data
        const userId = getState().user.data?.id
        dispatch(loadCatalogs({ playlist, userId }))
      })
      .catch(errorValidation)
  }
}

export const getAllPhonograms = () => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .get('/phonograms')
      .then(function (response) {
        const data = response.data.data
        dispatch(loadAllPhonograms(data))
      })
      .catch(errorValidation)
  }
}

export const loadImageRequest = (file: File) => {
  const formdata = new FormData()
  formdata.append('file', file)
  return request
    .post('/files', formdata, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(function (response) {
      const data = response.data.data
      return data
    })
    .catch(function (err) {
      const errStatusCode = err.response.data.statusCode
      if (errStatusCode === 1001) {
      }
    })
}

export const createPlaylist = (title: string, imageId: string, phonogramsIds: string[]) => {
  const image = imageId ? imageId : null
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .post('/playlists', {
        name: title,
        blackList: false,
        type: 'lk',
        playlistImageId: image,
        phonogramIds: phonogramsIds,
        exposedOks: false,
        exposedLk: true,
      })
      .then(function (response) {
        const data = response.data.data
        dispatch(getAllPhonograms())
      })
      .catch(errorValidation)
  }
}

export const getPlaylist = (id: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .get(`/playlists/${id}`)
      .then(function (response) {
        const data = response.data.data
        dispatch(loadTracks(data.phonograms))
      })
      .catch(errorValidation)
  }
}

export const editPlaylist = (
  title: string,
  imageId: string,
  id: string,
  userId: string | undefined,
  playlistType: string,
  exposedLk: boolean,
) => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .put(`/playlists/${id}`, {
        name: title,
        blackList: false,
        type: playlistType,
        playlistImageId: imageId,
        exposedOks: true,
        exposedLk: exposedLk,
        owner: userId,
      })
      .then(function (response) {
        const data = response.data.data
        dispatch(getCatalogs())
      })
      .catch(errorValidation)
  }
}

export const addPhonograms = (ids: string[], playlistId: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .post(`/playlists/${playlistId}/phonograms`, { addedPhonogramIds: ids })
      .then(function (response) {
        const data = response.data.data
      })
      .catch(errorValidation)
  }
}

export const deletePhonograms = (phonogramIds: string[], playlistId: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .put(`/playlists/${playlistId}/phonograms/deletion`, { deletedItemIds: phonogramIds })
      .then(function (response) {
        const data = response.data.data
      })
      .catch(errorValidation)
  }
}

export const getSettings = () => {
  return request
    .get('/settings')
    .then(function (response) {
      const data = response.data.data
      return data
    })
    .catch(function (err) {
      const errStatusCode = err.response.data.statusCode
      if (errStatusCode === 1001) {
      }
    })
}

const errorValidation = (err: any) => {
  const errStatusCode = err.response.data.statusCode
  if (errStatusCode === 1001) {
    console.log('Error')
  }
}
