import React, { FC, useEffect } from 'react'
import { Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import useStyles from 'pages/users/styles'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { deleteDevice } from 'store/modules/devices/actions'
import { Routes } from 'routes/routes'
import { TDevice } from 'interfaces/model/Devices'
import { loadSelectedDevice } from 'store/modules/selectedDevice/reducer'
import lockOpen from 'assets/icons/openLock.svg'
import bin from 'assets/icons/bin.svg'

type TProps = {
  device: TDevice
}

/*eslint-disable*/

export const DeviceCard: FC<TProps> = ({ device }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleDeleteDevice = () => {
    dispatch(deleteDevice(device.id))
  }
  const handleClick = (id: string | null) => {
    history.push(Routes.editDevice + '/' + id)
    dispatch(loadSelectedDevice(device))

  }
  return (
    <Card className={classes.root} variant="elevation" elevation={0}>
      <CardContent>
        <div className={classes.cardHeader}>
          {/*<LockIcon />*/}
          <CardActions>
            {/*<LockOpenIcon />*/}
            <img src={lockOpen} className={classes.openLock} alt="lockOpen" />
            <img src={bin} className={classes.bin} alt="bin" onClick={handleDeleteDevice}/>
            {/*<DeleteOutlineIcon onClick={handleDeleteDevice}/>*/}
          </CardActions>
        </div>
        <CardActions className={classes.credentialsContainer}  onClick={() => handleClick(device.id)}>
          <Typography className={classes.credentials}>{device.name}</Typography>
          <Typography className={classes.credentials}>{device.deviceInfo}</Typography>
        </CardActions>
      </CardContent>
    </Card>
  )
}
