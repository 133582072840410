import { request } from 'api'
import { AppDispatch } from 'store/store'
import { loadSelectedUser } from './reducer'
import { TPlayerUser } from 'interfaces/model/PlayerUsers'
import { updatePlayerUsers } from './reducer'

export const usersSelectedRequest = (id: string | null) => {
  return (dispatch: AppDispatch) => {
    request
      .get(`/users/players/${id}`)
      .then(function (response) {
        const data = response.data.data
        dispatch(loadSelectedUser(data))
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
        }
      })
  }
}
export const editSelectedUser = (id: string, selectedUser: TPlayerUser, navigateToUsersList: () => void) => {
  return (dispatch: AppDispatch) => {
    request.put(`/users/players/${id}`, selectedUser).then(function (response) {
      navigateToUsersList()
      dispatch(updatePlayerUsers(response.data.data))
    })
  }
}
