import styled from 'styled-components'

export const Wrapper = styled.section`
  min-height: 100vh;
  background: linear-gradient(151.53deg, #330255 1.35%, #000722 46.53%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
  padding: 10vh;
`

export const OutlineContainer = styled.div`
  width: 40vw;
  height: 80vh;
  position: relative;
  background: linear-gradient(151.53deg, #200442 1.35%, #000722 46.53%);
  border-radius: 8px;
  align-items: center;

  &: before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-image: linear-gradient(to top, #0084d1 0%, #4233b0 50%, #a902f6 100%);
    border-radius: 8px;
    z-index: -1;
  }

  @media (max-width: 1460px) {
    width: 50vw;
  }
  @media (max-width: 768px) {
    width: 70vw;
  }
`

export const Container = styled.div`
  // padding-top: 40px;
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 80vh;

  padding: 40px;
  position: relative;
  text-align: center;
  background: linear-gradient(151.53deg, #200442 1.35%, #000722 46.53%);
  border-radius: 23px;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  ::webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  @media (max-width: 1460px) {
    width: 50vw;
  }
  @media (max-width: 768px) {
    width: 70vw;
  }
`
export const OutlineSmallContainer = styled(OutlineContainer)`
  margin-top: 20px;
  height: 80vh;
  @media (max-width: 1152px) {
    height: 70vh;
  }
`
export const SmallContainer = styled(Container)`
  @media (max-width: 1152px) {
    height: 70vh;
  }
`
export const SubTitle = styled.div`
  margin-top: 36px;
  font-style: bold;
  font-weight: 700;
  font-size: 24px;
  @media (max-width: 1151px) {
    margin-top: 16px;
    font-style: bold;
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-style: bold;
    font-size: 14px;
    line-height: 16px;
  }
`
