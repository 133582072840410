import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { TState } from '../../store'
import { ImageFile, TCatalog, TPhonogram, TPlaylist } from './types'

const initialState: TCatalog = {
  playlists: [] as TPlaylist[],
  myPlaylists: [] as TPlaylist[],
  tracks: [] as TPhonogram[],
  phonograms: [] as TPhonogram[],
  imageUrl: '',
}

const catalogs = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    loadCatalogs(state: TCatalog, action: PayloadAction<{ playlist: TPlaylist[]; userId: string | undefined }>) {
      const allCatalogs = [] as TPlaylist[]
      const myCatalogs = [] as TPlaylist[]
      action.payload.playlist.forEach((item: TPlaylist) => {
        item.ownerId === action.payload.userId ? myCatalogs.push(item) : allCatalogs.push(item)
      })
      const catalogs = allCatalogs.filter((item) => item.exposedOks === true)
      state.playlists = catalogs
      state.myPlaylists = myCatalogs
    },
    loadMyCatalogs(state: TCatalog, action: PayloadAction<TPlaylist[]>) {
      state.myPlaylists = action.payload
    },
    loadTracks(state: TCatalog, action: PayloadAction<TPhonogram[]>) {
      state.tracks = action.payload
    },
    loadAllPhonograms(state: TCatalog, action: PayloadAction<TPhonogram[]>) {
      state.phonograms = action.payload
    },
    loadImageUrl(state: TCatalog, action: PayloadAction<ImageFile>) {
      state.imageUrl = action.payload.id
    },
  },
})

export const { loadCatalogs, loadTracks, loadAllPhonograms, loadImageUrl } = catalogs.actions

export default catalogs.reducer
