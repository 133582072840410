import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TUserToken, TAuthState } from 'store/modules/auth/types'

const initialState: TAuthState = {
  user: null,
  isLoading: false,
  isLoaded: false,
  authSuccess: false,
  userUnauthorized: false,
}

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserToken(state: TAuthState, action: PayloadAction<TUserToken>) {
      state.user = action.payload
    },
    setUser(state: TAuthState, action: PayloadAction<TUserToken | null>) {
      state.user = action.payload
    },
    isLoading(state: TAuthState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    isLoaded(state: TAuthState, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload
    },
    setAuthSuccess(state: TAuthState, action: PayloadAction<boolean>) {
      state.authSuccess = action.payload
    },
    setUserUnauthorized(state: TAuthState, action: PayloadAction<boolean>) {
      state.userUnauthorized = action.payload
    },
  },
})

export const { setUserToken, setUser, isLoading, setAuthSuccess, isLoaded, setUserUnauthorized } = userSlice.actions

export default userSlice.reducer
