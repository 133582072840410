import React, { FC, useEffect } from 'react'
import { BaseLayout } from 'components/baseLayout'
import { Grid, Typography } from '@material-ui/core'
import { DeviceCard } from 'pages/devices/components/card'
import useStyles from 'pages/users/styles'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store/store'
import { TDevice } from 'interfaces/model/Devices'
import { devicesListRequest } from 'store/modules/devices/actions'

export const PlayersPage: FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  useEffect(() => {
    dispatch(devicesListRequest())
  }, [])

  const devices = useSelector((state: TState) => state.devices.devices)

  return (
    <BaseLayout>
      <div className={classes.cardContent}>
        <div>
          <Typography className={classes.usersNumber}>{`Плееров ${devices.length} из 5`} </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            {devices.map((device: TDevice) => (
              <Grid item xs={3} key={device.id}>
                <DeviceCard device={device} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </BaseLayout>
  )
}
