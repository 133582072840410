import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TDevice, TSelectedDeviceState } from 'interfaces/model/Devices'

const initialState: TSelectedDeviceState = {
  data: null,
  // selectedDevice: null,
  isLoading: false,
  isLoaded: false,
}

const selectedDeviceSlice = createSlice({
  name: 'selectedDevice',
  initialState,
  reducers: {
    loadSelectedDevice(state: TSelectedDeviceState, action: PayloadAction<TDevice>) {
      state.data = action.payload
      state.isLoaded = true
      state.isLoaded = false
    },
    updateDevice(state: TSelectedDeviceState, action: PayloadAction<TDevice>) {
      state.data = action.payload
    },
  },
})

export const { loadSelectedDevice, updateDevice } = selectedDeviceSlice.actions

export default selectedDeviceSlice.reducer
