import { request } from 'api'
import { LoginFormState } from 'pages/auth/loginPage/LoginForm'
import { AppDispatch } from 'store/store'
import { isLoaded, isLoading, setAuthSuccess, setUser } from 'store/modules/auth/reducer'

export const authRequest = (data: LoginFormState) => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .post('/users/signin/email', {
        ...data,
        type: 'lk',
      })
      .then(function (response) {
        const data = response.data.data
        localStorage.setItem('token', data.token)
        dispatch(isLoading(false))
        dispatch(setAuthSuccess(true))
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
          dispatch(isLoading(false))
        }
      })
  }
}

export const logoutRequest = () => {
  return (dispatch: AppDispatch) => {
    request
      .post('/users/logout')
      .then((response) => {
        dispatch(setUser(null))
        localStorage.removeItem('token')
      })
      .catch((err) => console.log('logout request error: ', err))
  }
}
