import React, { FC, useEffect } from 'react'
import { BaseLayout } from 'components/baseLayout'
import styled from 'styled-components'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { Playlists } from './playlists/index'
import { Routes } from '../../routes/routes'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomTable } from './components/table'
import { TState } from '../../store/store'
import { TPhonogram } from '../../store/modules/catalogs/types'
import { getAllPhonograms } from '../../store/modules/catalogs/actions'
import { createStyles, FormControl, makeStyles, Select, Theme, Typography } from '@material-ui/core'
import { Charts } from 'pages/catalogs/charts'

const phonogramsType = ['Все жанры', 'Рок', 'Классика', 'Поп']

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      height: 32,
      border: '1px solid #E0E0E0',
      boxSizing: 'border-box',
      borderRadius: 27,
      '&:root': {
        backgroundColor: 'none',
      },
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: 24,
      paddingTop: 24,
    },
  }),
)

const STabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
`
const STabList = styled(TabList)`
  list-style-type: none;
  padding: 4px;
  display: flex;
  margin: 0;
`

const STab = styled(Tab)`
  margin-right: 25px;
  padding: 4px 25px 4px 25px;
  user-select: none;
  cursor: arrow;
  background: #f8f9fb;
  border-radius: 16px;

  &.is-selected {
    background: linear-gradient(to left, #0084d1 6.32%, #4233b0 50.38%, #a902f6 94.43%);
    color: #f5f5f5;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
  }
`

const STabPanel = styled(TabPanel)`
  display: none;
  min-height: 40vh;
  padding: 4px;
  margin-top: -5px;

  &.is-selected {
    display: block;
  }
`

export const CataloguesPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const phonograms = useSelector((state: TState) => state.catalogs.phonograms)
  const [isCreate, setCreate] = React.useState(false)
  const [genre, setGenre] = React.useState<string>(phonogramsType[0])
  const [filteredPlaylist, setFilteredPlaylist] = React.useState(phonograms)
  const date = new Date().toLocaleDateString()
  const [tabIndex, setTabIndex] = React.useState(0)

  const handleClickPlaylists = () => {
    history.push(Routes.playlists)
  }

  const handleClickAllTracks = () => {
    history.push(Routes.catalogues)
  }

  const handleClickCharts = () => {
    history.push(Routes.charts)
  }

  const handleChoose = (item: TPhonogram) => {
    console.log('Нажал')
  }

  const handleCreate = () => {
    setCreate(!isCreate)
  }

  const changeGenreState = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const genre = event.target.value as string
    setGenre(genre)
    const filteredPlaylist =
      genre !== phonogramsType[0] ? phonograms.filter((item: TPhonogram) => item.genre === genre) : phonograms
    setFilteredPlaylist(filteredPlaylist)
  }

  useEffect(() => {
    ;(async () => {
      await dispatch(getAllPhonograms())
    })()
  }, [])

  useEffect(() => {
    setFilteredPlaylist(phonograms)
  }, [phonograms])
  return (
    <BaseLayout>
      <STabs
        selectedTabClassName="is-selected"
        selectedTabPanelClassName="is-selected"
        selectedIndex={tabIndex}
        onSelect={(index) => {
          console.log(index), setTabIndex(index)
        }}
      >
        <STabList style={{ display: isCreate ? 'none' : 'flex' }} disabled={isCreate}>
          <STab onClick={handleClickAllTracks}> Все треки </STab>
          <STab onClick={handleClickCharts}> Чарты </STab>
          <STab onClick={handleClickPlaylists}> Плейлисты </STab>
        </STabList>
        <STabPanel>
          <div className={classes.title}>
            <Typography variant={'body1'} style={{ paddingRight: 18 }}>
              {'Доступен до: ' + date}
            </Typography>
            <Typography variant={'body1'}>{'Последнее обновление: ' + date}</Typography>
          </div>
          <FormControl style={{ paddingBottom: 24 }}>
            <Select
              value={genre}
              onChange={changeGenreState}
              className={classes.input}
              native
              disableUnderline
              style={{ paddingLeft: 16 }}
            >
              {phonogramsType.map((item: string, index: number) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <CustomTable handleChoose={handleChoose} tracks={filteredPlaylist} withChoose={false} />
        </STabPanel>
        <STabPanel>
          <Charts />
        </STabPanel>
        <STabPanel>
          <Playlists handleCreate={handleCreate} />
        </STabPanel>
      </STabs>
    </BaseLayout>
  )
}
