import { DefaultTheme } from 'styled-components'

const mainTheme: DefaultTheme = {
  borderRadius: '5px',

  colors: {
    main: '#FFFFFF',
    secondary: '#f5f5f5',
  },
  textColors: {
    main: '#3E4347',
    secondary: '#f5f5f5',
  },
}

export { mainTheme }
