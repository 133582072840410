import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPlayerUser } from 'interfaces/model/PlayerUsers'
import { TSelectedPlayerUserState } from 'interfaces/model/SelectedPlayerUser'

const initialState: TSelectedPlayerUserState = {
  data: null,
  isLoading: false,
  isLoaded: false,
  isCreated: false,
}

const selectedPlayerUserSlice = createSlice({
  name: 'selectedUser',
  initialState,
  reducers: {
    loadSelectedUser(state: TSelectedPlayerUserState, action: PayloadAction<TPlayerUser>) {
      state.data = action.payload
      state.isLoaded = true
      state.isLoaded = false
    },
    updatePlayerUsers(state: TSelectedPlayerUserState, action: PayloadAction<TPlayerUser>) {
      state.data = action.payload
    },
    // addedPlayerUser(state: TUsersState) {
    //   state.isCreated = true
    // },
    // deletePlayerUserSuccess(state: TUsersState, action: PayloadAction<string | null>) {
    //   state.clients = state.clients.filter((id) => id.id !== action.payload)
    //   // state.isDeletedSuccess = action.payload
    // },
  },
})

export const { loadSelectedUser, updatePlayerUsers } = selectedPlayerUserSlice.actions

export default selectedPlayerUserSlice.reducer
