import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TDevice, TDevicesListState } from 'interfaces/model/Devices'

const initialState: TDevicesListState = {
  devices: [] as TDevice[],
  isCreated: false,
}

const userEditSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    updateDevices(state: TDevicesListState, action: PayloadAction<TDevice[]>) {
      state.devices = action.payload
    },

    deleteDeviceSuccess(state: TDevicesListState, action: PayloadAction<string | null>) {
      state.devices = state.devices.filter((id) => id.id !== action.payload)
    },
  },
})

export const { updateDevices, deleteDeviceSuccess } = userEditSlice.actions

export default userEditSlice.reducer
