import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 40,
    display: 'flex',
    justifyContent: 'space-between',
  },

  button: {
    color: '#BDBDBD',
  },
  createPasButton: {
    color: '#2164A9',
    marginTop: 40,
    fontWeight: 'bold',
    // fontSize: theme.typography.button,
    fontSize: 16,
    backgroundColor: '#FFFFFF',
    border: '1px solid #2164A9',
    borderRadius: 4,
  },
}))

export default useStyles
