import React from 'react'
import styled from 'styled-components'

type ButtonProps = {
  primary?: boolean
}

export const Button = styled.button`
  background: ${(props: ButtonProps) =>
    props.primary ? 'linear-gradient(to left, #0084d1 0%, #4233b0 50%, #a902f6 100%)' : '#2164A9'};
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  line-height: 28px;
  padding: 16px;
  width: 25vw;
  font-weight: bold;

  border-radius: 8px;
  margin-top: 24px;
  @media (max-width: 1152px) {
    width: 39vw;
    font-size: 18px;
    line-height: 21px;
    margin-top: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
`
export const PrimaryButton = styled(Button)`
  min-width: 13vw;
  // border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  padding: 0 15px 0 15px;
`
export const SaveButton = styled(Button)`
  margin-top: 0;
  width: 18vw;
  height: 32px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  padding: 0 10px 0 10px;
  margin: 12px 10px;
  @media (min-width: 1460px) {
    width: 11vw;
  }
`

export const LogoutButton = styled(PrimaryButton)`
  display: flex;
  background: ${(props: ButtonProps) => (props.primary ? 'transparent' : 'transparent')};
  color: #2164a9;
  border: none;
  font-weight: bold;
  width: 15vw;
`
export const ButtonText = styled.div`
  margin-left: 0.5vw;
`

export const Span = styled.span`
  text-decoration: underline;
`
