import React, { FC } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PrivateRoute } from 'routes/private-route'
import { Routes } from 'routes/routes'
import { AuthPage } from 'pages/auth'
import { RegistrationPage } from 'pages/auth/registrationPage'
import { ProfilePage } from 'pages/profile/index'
import { HomePage } from 'pages/home'
import { CataloguesPage } from 'pages/catalogs'
import { RestorePasswordPage } from 'pages/auth/restorePassword'
import { PlayersPage } from 'pages/devices'
import { UsersPage } from 'pages/users'
import { UserEditPage } from 'pages/users/userEdit'
import { UserCreatePage } from 'pages/users/userCreate'
import { DeviceEditPage } from 'pages/devices/components/deviceEdit'
import { StatisticsPage } from 'pages/statistics'

export const RootRoute: FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path={Routes.auth}>
        <AuthPage />
      </Route>
      <Route path={Routes.signup}>
        <RegistrationPage />
      </Route>
      <Route path={Routes.restorePassword}>
        <RestorePasswordPage />
      </Route>
      <PrivateRoute exact path={Routes.user}>
        <ProfilePage />
      </PrivateRoute>

      <PrivateRoute exact path={Routes.users}>
        <UsersPage />
      </PrivateRoute>
      <PrivateRoute exact path={Routes.newUser}>
        <UserCreatePage />
      </PrivateRoute>

      <PrivateRoute exact path={Routes.editUser.toString() + '/:id'}>
        <UserEditPage />
      </PrivateRoute>

      <PrivateRoute exact path={Routes.editDevice.toString() + '/:id'}>
        <DeviceEditPage />
      </PrivateRoute>

      <PrivateRoute path={Routes.players}>
        <PlayersPage />
      </PrivateRoute>
      <PrivateRoute path={Routes.catalogues}>
        <CataloguesPage />
      </PrivateRoute>

      <PrivateRoute path={Routes.statistics}>
        <StatisticsPage />
      </PrivateRoute>

      <PrivateRoute path={Routes.home}>
        <HomePage />
      </PrivateRoute>
    </Switch>
  </BrowserRouter>
)
