import axios from 'axios'
import { config } from 'config/config'

export const request = axios.create({
  baseURL: config.baseUrl,
  responseType: 'json',
})

request.interceptors.request.use(
  (config) => {
    console.log('interceptor setting up')
    const token = localStorage.getItem('token')
    console.log(token)
    if (token != null) {
      config.headers.authorization = `Bearer ${token}`
    }
    console.log(config)
    return config
  },
  (error) => Promise.reject(error),
)
