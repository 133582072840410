import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store/store'
import {
  AppBar,
  Box,
  FormControl,
  InputAdornment,
  InputBase,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { TPhonogram, TPlaylist } from 'store/modules/catalogs/types'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { ArrowBack, Search } from '@material-ui/icons'
import {
  addPhonograms,
  createPlaylist,
  deletePhonograms,
  editPlaylist,
  getPlaylist,
  loadImageRequest,
} from 'store/modules/catalogs/actions'
import { COLORS } from 'theme/colors/colors'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
import { CustomTable } from '../../components/table'

const phonogramsType = ['Все жанры', 'Рок', 'Классика', 'Поп']
interface IProps {
  playlist: TPlaylist
  handleBack: () => void
  storageUrl: string
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      borderRadius: theme.spacing(2),
      height: 135,
      width: 135,
      justifyContent: 'center',
      paddingTop: 5,
      flexDirection: 'column',
      display: 'flex',
      textAlign: 'center',
      position: 'relative',
    },
    text: {
      padding: 8,
      justifyContent: 'center',
      alignSelf: 'center',
      color: COLORS.BLUE_MAIN,
    },
    media: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: theme.spacing(2),
      width: 135,
      height: 135,
    },
    addIcon: {
      height: 20,
      width: 20,
      alignSelf: 'center',
      fill: 'linear-gradient(278.24deg, #0084D1 6.32%, #4233B0 50.38%, #A902F6 94.43%)',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    container: {
      width: '100%',
      height: '85vh',
      display: 'flex',
      flexDirection: 'column',
    },
    inputFile: {
      opacity: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: 26,
    },
    tables: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      height: '50%',
      paddingTop: 24,
    },
    button: {
      border: 1,
      borderColor: COLORS.BLUE_MAIN,
      color: COLORS.BLUE_MAIN,
      borderStyle: 'solid',
      textTransform: 'none',
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '140%',
    },
    tableWithSearch: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      paddingRight: 18,
    },
    inputTitle: {
      width: 300,
      height: 32,
    },
    titles: {
      display: 'flex',
      justifyContent: 'flex-start',
      paddingTop: 24,
      paddingBottom: 5,
    },
    genre: {
      width: 150,
      border: '1px solid #E0E0E0',
      boxSizing: 'border-box',
      borderRadius: 16,
    },
    input: {
      height: 32,
      border: '1px solid #E0E0E0',
      boxSizing: 'border-box',
      borderRadius: 27,
      paddingLeft: 16,
      '&:root': {
        backgroundColor: 'none',
      },
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabs: {
      background: '#FFFFFF',
    },
  }),
)

export const CreatePlaylist = ({ playlist, handleBack, storageUrl }: IProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userId = useSelector((state: TState) => state.user.data?.id)
  const tracks = useSelector((state: TState) => state.catalogs.tracks)
  const phonograms = useSelector((state: TState) => state.catalogs.phonograms)
  const [localePlaylist, setLocalePlaylist] = React.useState<TPhonogram[]>(playlist.id ? tracks : [])
  const [newPhonogramsIds, setNewPhonogramsIds] = React.useState<string[]>([])
  const [deletedPhonogramsIds, setDeletedPhonogramsIds] = React.useState<string[]>([])
  const [filteredPlaylist, setFilteredPlaylist] = React.useState(phonograms)
  const [title, setTitle] = React.useState<string>(playlist.name)
  const [file, setFile] = React.useState({} as File)
  const [urlFile, setUrl] = React.useState<string>('')
  const id = useSelector((state: TState) => state.user.data?.id)
  const [genre, setGenre] = React.useState<string>(phonogramsType[0])
  const [localGenre, setLocalGenre] = React.useState<string>(phonogramsType[0])
  const isImageExist = playlist.playlistImage?.relativePath || urlFile.length !== 0
  const imageLink = useMemo(
    () => (playlist.playlistImage?.relativePath ? storageUrl + '/' + playlist.playlistImage.relativePath : urlFile),
    [playlist, storageUrl],
  )
  const isTablet = window.innerWidth < 800
  const [tabs, setTab] = React.useState<string>('all')
  useEffect(() => {
    ;(async () => {
      await dispatch(getPlaylist(playlist.id))
    })()
  }, [])

  useEffect(() => {
    setLocalePlaylist(tracks)
  }, [tracks])

  const handleCreate = async () => {
    const phonogramsIds = [] as string[]
    let imageId = ''
    if (urlFile.length > 0) {
      const response = await loadImageRequest(file)
      imageId = response.id
    }
    if (tracks.length === 0) {
      localePlaylist.map((item: TPhonogram) => {
        phonogramsIds.push(item.id)
      })
      await dispatch(createPlaylist(title, imageId, phonogramsIds))
    } else {
      await dispatch(editPlaylist(title, imageId, playlist.id, id, 'lk', true))
      newPhonogramsIds.length && (await dispatch(addPhonograms(newPhonogramsIds, playlist.id)))
      deletedPhonogramsIds.length && (await dispatch(deletePhonograms(newPhonogramsIds, playlist.id)))
    }
    handleBack()
  }

  const handleClickAdd = (item: TPhonogram) => {
    const updatedDeleted = deletedPhonogramsIds.filter((phonogram) => phonogram !== item.id)
    setDeletedPhonogramsIds(updatedDeleted)
    setNewPhonogramsIds([...newPhonogramsIds, item.id])
    setLocalePlaylist([...localePlaylist, item])
  }

  const handleClickRemove = (item: TPhonogram) => {
    const updatedPhonograms = newPhonogramsIds.filter((phonogram) => phonogram !== item.id)
    setNewPhonogramsIds(updatedPhonograms)
    setDeletedPhonogramsIds([...deletedPhonogramsIds, item.id])
    const updatedLocal = localePlaylist.filter((phonogram) => phonogram.id !== item.id)
    setLocalePlaylist(updatedLocal)
  }

  const handleAddImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files ? event.target.files[0] : undefined
    if (image) {
      const result = await toBase64(image)
      const reader = new FileReader()
      reader.readAsDataURL(image)
      setUrl(result as string)
      setFile(image)
    }
  }

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const changeFilterState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredPlaylist = phonograms.filter((item: TPhonogram) => item.name.includes(event.target.value))
    setFilteredPlaylist(filteredPlaylist)
  }

  const changeFilterLocaleState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredPlaylist = localePlaylist.filter((item: TPhonogram) => item.name.includes(event.target.value))
    setLocalePlaylist(filteredPlaylist)
  }

  const changeGenreState = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const genre = event.target.value as string
    setGenre(genre)
    const filteredPlaylist =
      genre !== phonogramsType[0] ? phonograms.filter((item: TPhonogram) => item.genre === genre) : phonograms
    setFilteredPlaylist(filteredPlaylist)
  }

  const changeGenreLocaleState = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const genre = event.target.value as string
    setLocalGenre(localGenre)
    const filteredPlaylist =
      genre !== phonogramsType[0] ? localePlaylist.filter((item: TPhonogram) => item.genre === genre) : tracks
    setLocalePlaylist(filteredPlaylist)
  }

  const handleChangeTabs = (event: React.ChangeEvent<unknown>, newValue: string) => {
    event.preventDefault()
    event.stopPropagation()
    setTab(newValue)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <ArrowBack onClick={handleBack} />
        {(playlist.ownerId === undefined || userId === playlist.ownerId) && (
          <Button disableRipple onClick={handleCreate} className={classes.button} variant="outlined" color={'primary'}>
            {'Сохранить изменения'}
          </Button>
        )}
      </div>
      <div style={{ overflowX: 'hidden' }}>
        <InputBase
          type="text"
          inputProps={{ 'aria-label': 'naked', 'padding-left': '18px' }}
          placeholder={'Название'}
          onChange={(event) => setTitle(event.target.value)}
          value={title}
          style={{ border: '1px solid #E0E0E0', borderRadius: 4, paddingLeft: 16 }}
        />
        <>
          <Typography variant={'body1'} className={classes.titles}>
            {'Обложка'}
          </Typography>
          {isImageExist ? (
            <div className={classes.content}>
              <img src={imageLink} className={classes.media} />
              <input multiple type="file" accept={'image/*'} className={classes.inputFile} onChange={handleAddImage} />
            </div>
          ) : (
            <Paper className={classes.content} elevation={0} style={{ backgroundColor: '#F8F9FB' }}>
              <input multiple type="file" accept={'image/*'} className={classes.inputFile} onChange={handleAddImage} />
              <AddIcon className={classes.addIcon} />
              <Typography variant={'body2'} className={classes.text}>
                {'Добавить обложку'}
              </Typography>
            </Paper>
          )}
        </>
        <div className={classes.tables}>
          {!isTablet ? (
            <>
              <div className={classes.tableWithSearch}>
                <Typography
                  variant={'body1'}
                  className={classes.titles}
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {'Все треки'}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 17,
                  }}
                >
                  <FormControl>
                    <Select
                      native
                      value={genre}
                      onChange={changeGenreState}
                      className={classes.input}
                      disableUnderline
                      style={{ paddingLeft: 16 }}
                    >
                      {phonogramsType.map((item: string, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputBase
                      type="text"
                      inputProps={{ 'aria-label': 'naked' }}
                      placeholder={'Поиск по каталогу'}
                      startAdornment={
                        <InputAdornment position="start" style={{ color: '#1A2550' }}>
                          <Search />
                        </InputAdornment>
                      }
                      className={classes.input}
                      onChange={changeFilterState}
                    />
                  </FormControl>
                </div>
                <CustomTable
                  tracks={filteredPlaylist}
                  handleChoose={handleClickAdd}
                  isCreated={false}
                  withChoose={true}
                />
              </div>
              <div className={classes.tableWithSearch}>
                <Typography
                  variant={'body1'}
                  className={classes.titles}
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {'Плейлист'}
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 17,
                  }}
                >
                  <FormControl>
                    <Select
                      native
                      value={localGenre}
                      onChange={changeGenreLocaleState}
                      className={classes.input}
                      disableUnderline
                      style={{ paddingLeft: 16 }}
                    >
                      {phonogramsType.map((item: string, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputBase
                      type="text"
                      inputProps={{ 'aria-label': 'naked' }}
                      placeholder={'Поиск по каталогу'}
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                      className={classes.input}
                      onChange={changeFilterLocaleState}
                    />
                  </FormControl>
                </div>
                <CustomTable
                  tracks={localePlaylist}
                  handleChoose={handleClickRemove}
                  isCreated={true}
                  withChoose={true}
                />
              </div>
            </>
          ) : (
            <div className={classes.root}>
              <AppBar position="static" style={{ boxShadow: 'none' }}>
                <Tabs
                  value={tabs}
                  onChange={(event, value) => handleChangeTabs(event, value)}
                  indicatorColor="primary"
                  textColor="primary"
                  className={classes.tabs}
                  variant="fullWidth"
                >
                  <Tab label="Все треки" value="all" className={classes.titles} style={{ fontWeight: 'bold' }} />
                  <Tab label="Плейлист" value="created" className={classes.titles} style={{ fontWeight: 'bold' }} />
                </Tabs>
              </AppBar>
              <TabPanel value={tabs} className={classes.tableWithSearch} index="all" style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 17,
                  }}
                >
                  <FormControl>
                    <Select
                      native
                      value={genre}
                      onChange={changeGenreState}
                      className={classes.input}
                      disableUnderline
                      inputProps={{ 'aria-label': 'naked' }}
                    >
                      {phonogramsType.map((item: string, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputBase
                      type="text"
                      inputProps={{ 'aria-label': 'naked' }}
                      placeholder={'Поиск по каталогу'}
                      startAdornment={
                        <InputAdornment position="start" style={{ color: '#1A2550' }}>
                          <Search />
                        </InputAdornment>
                      }
                      className={classes.input}
                      onChange={changeFilterState}
                    />
                  </FormControl>
                </div>
                <CustomTable
                  tracks={filteredPlaylist}
                  handleChoose={handleClickAdd}
                  isCreated={false}
                  withChoose={true}
                />
              </TabPanel>
              <TabPanel value={tabs} className={classes.tableWithSearch} index="created" style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingBottom: 17,
                  }}
                >
                  <FormControl>
                    <Select
                      native
                      value={localGenre}
                      onChange={changeGenreLocaleState}
                      className={classes.input}
                      disableUnderline
                      style={{ paddingLeft: 16 }}
                    >
                      {phonogramsType.map((item: string, index: number) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputBase
                      type="text"
                      inputProps={{ 'aria-label': 'naked' }}
                      placeholder={'Поиск по каталогу'}
                      startAdornment={
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      }
                      className={classes.input}
                      onChange={changeFilterLocaleState}
                    />
                  </FormControl>
                </div>
                <CustomTable
                  tracks={localePlaylist}
                  handleChoose={handleClickRemove}
                  isCreated={true}
                  withChoose={true}
                />
              </TabPanel>
              {/*</TabContext>*/}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
