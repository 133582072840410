export enum Routes {
  home = '/',
  user = '/user',
  signup = '/signup',
  auth = '/auth',
  restorePassword = '/restorePassword',
  users = '/users',
  newUser = '/users/add',
  editUser = '/users/edit',
  players = '/players',
  editDevice = '/device/edit',
  catalogues = '/catalogues',
  statistics = '/statistics',
  charts = '/catalogues/charts',
  playlists = '/catalogues/playlists',
  createPlaylist = '/catalogues/playlists/createPlaylist',
  editPlaylist = '/catalogues/playlists/editPlaylist/',
}
