import { request } from 'api'
import { AppDispatch, TState } from 'store/store'
import { updatePlayerUsers, addedPlayerUser, deletePlayerUserSuccess } from './reducer'
import { TPlayerUser } from 'interfaces/model/PlayerUsers'

export const usersListRequest = () => {
  return (dispatch: AppDispatch) => {
    request
      .get('/users/players')
      .then(function (response) {
        const data = response.data.data
        dispatch(updatePlayerUsers(data))
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
        }
      })
  }
}

export const userCreateRequest = (data: TPlayerUser, navigateToUsersList: () => void) => {
  return (dispatch: AppDispatch, getState: () => TState) => {
    const companyId = getState().user.data?.company.id
    request
      .post('/users/players', { ...data, companyId })
      .then(function (response) {
        navigateToUsersList()
        dispatch(addedPlayerUser())
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
        }
      })
  }
}

export const deleteUser = (id: string | null) => {
  return (dispatch: AppDispatch) => {
    request.delete(`/users/players/${id}`).then(function (response) {
      dispatch(deletePlayerUserSuccess(id))
    })
  }
}
