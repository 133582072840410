import React, { FC } from 'react'
import avatarUpload from 'assets/icons/avatarUpload.svg'
import styled from 'styled-components'

export const Avatar = styled.div`
  margin-left: 15px;
`

export const AddAvatar: FC = () => {
  return (
    <Avatar>
      <img src={avatarUpload} />
    </Avatar>
  )
}
