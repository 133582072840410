import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { BaseLayout } from '../../../components/baseLayout'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../store/store'
import { Paper, Typography, Grid } from '@material-ui/core'
import { PlaylistItem } from './PlaylistItem'
import { TCatalog, TPlaylist, TPhonogram } from '../../../store/modules/catalogs/types'
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import { CreatePlaylist } from './createPlaylist'
import { getAllPhonograms, getCatalogs, getPlaylist, getSettings } from '../../../store/modules/catalogs/actions'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../routes/routes'
import { COLORS } from '../../../theme/colors/colors'
import { ReactComponent as AddIcon } from 'assets/icons/add.svg'
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 3000, min: 1700 },
    items: 9,
  },
  largeDesktop: {
    breakpoint: { max: 1700, min: 1400 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 1400, min: 1300 },
    items: 7,
  },
  tablet: {
    breakpoint: { max: 1300, min: 600 },
    items: 6,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 3,
  },
}

const TabContent = styled.div`
  display: flex;
  flex-direction: column;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      width: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      height: 135,
    },
    content: {
      borderRadius: theme.spacing(2),
      height: 135,
      width: 135,
      justifyContent: 'center',
      paddingTop: 5,
      flexDirection: 'column',
      display: 'flex',
    },
    addIcon: {
      height: 20,
      width: 20,
      alignSelf: 'center',
    },
    text: {
      padding: 8,
      alignSelf: 'center',
      color: COLORS.BLUE_MAIN,
    },
    titles: {
      padding: 24,
      paddingLeft: 0,
      fontWeight: 'bold',
    },
  }),
)

interface IProps {
  handleCreate: () => void
}

export const Playlists = ({ handleCreate }: IProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const [isCreate, setCreate] = useState(false)
  const [playlist, setPlaylist] = useState({} as TPlaylist)
  const [storageUrl, setUrl] = useState('')
  const handleClick = async (item: TPlaylist, event?: React.MouseEvent<unknown>) => {
    handleCreate()
    await setPlaylist(item)
    await setCreate(!isCreate)
    item.id ? history.push(Routes.editPlaylist + item.id) : history.push(Routes.createPlaylist)
  }
  useEffect(() => {
    ;(async () => {
      await dispatch(getCatalogs())
      const response = await getSettings()
      setUrl(response.cdnUrl)
    })()
  }, [])
  const { playlists, myPlaylists } = useSelector((state: TState) => state.catalogs)
  const handleBack = () => {
    handleCreate()
    setCreate(!isCreate)
    history.push(Routes.playlists)
  }
  return !isCreate ? (
    <>
      <Typography variant="body1" className={classes.titles}>
        {'ОКС'}
      </Typography>
      <Carousel responsive={responsive} showDots>
        {playlists.map((item: TPlaylist) => {
          return (
            <div key={item.id}>
              <PlaylistItem playlist={item} key={item.id} storageUrl={storageUrl} handleOpen={handleClick} />
            </div>
          )
        })}
      </Carousel>
      <Typography variant="body1" className={classes.titles}>
        {'Мои плейлисты'}
      </Typography>
      <Carousel responsive={responsive} showDots>
        <Grid item>
          <Paper
            className={classes.content}
            onClick={(event) => handleClick({} as TPlaylist, event)}
            style={{ backgroundColor: '#F8F9FB' }}
            elevation={0}
          >
            <AddIcon className={classes.addIcon} />
            <Typography variant={'body2'} className={classes.text}>
              {'Новый плейлист'}
            </Typography>
          </Paper>
        </Grid>
        {myPlaylists.map((item: TPlaylist) => {
          return (
            <Grid item key={item.id}>
              <PlaylistItem playlist={item} storageUrl={storageUrl} handleOpen={handleClick} />
            </Grid>
          )
        })}
      </Carousel>
    </>
  ) : (
    <>
      <CreatePlaylist playlist={playlist} handleBack={handleBack} storageUrl={storageUrl} />
    </>
  )
}
