import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { InputProps, TextField } from '@material-ui/core'
// import { FieldErrors } from 'react-hook-form/dist/types/form'
// import { ErrorMessage } from '@hookform/error-message'

export type TField = {
  title: string
  maxWidth?: number
  type?: string
  disabled?: boolean
  multiline?: boolean
  rows?: number | string
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    // paddingRight: 5,
    fontSize: 10,
    lineHeight: '140%',
    width: '40%',
    maxWidth: 240,
  },
  inputContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    maxWidth: 240,
  },
  textField: {
    flexGrow: 1,
    height: 'auto',
    color: theme.palette.primary.main,
    // border: '1px solid #e0e0e0',
    borderRadius: 6,

    '& .MuiInputBase-multiline': {
      padding: '7px 0',
    },

    '& .MuiAutocomplete-inputRoot': {
      paddingTop: [0, '!important'],
      paddingBottom: [0, '!important'],
    },

    '& input, textarea': {
      padding: '0 7px !important',
      height: 'auto',
      minHeight: 32,
    },
  },
  error: {
    color: theme.palette.secondary.main,
  },
}))

export type TextFieldInputProps = {
  field: TField
  // errors?: FieldErrors
  name?: string
  value?: any
  InputProps?: InputProps
}
/*eslint-disable*/
const TextFieldInput: React.FC<TextFieldInputProps> = ({ field, ...props }) => {
  const classes = useStyles()

  // const { type, title, disabled, maxWidth, multiline } = field
  const { type, title, disabled, maxWidth, multiline } = field

  return (
    <div className={classes.container}>
      <div style={{ maxWidth: maxWidth || 240 }} className={classes.label}>
        {title}
      </div>
      <div className={classes.inputContainer}>
        <TextField {...props} className={classes.textField} variant="outlined" />
        {/*{errors && <ErrorMessage as="span" className={classes.error} errors={errors} name={name} />}*/}
      </div>
    </div>
  )
}

export default TextFieldInput
