import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TRegistrationState, TUserToken } from 'store/modules/auth/types'

const initialState: TRegistrationState = {
  user: null,
  isLoading: false,
  isLoaded: false,
  registrationSuccess: false,
  userUnregistrated: false,
}

const registrationSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setUserToken(state: TRegistrationState, action: PayloadAction<TUserToken>) {
      state.user = action.payload
    },
    setUser(state: TRegistrationState, action: PayloadAction<null>) {
      state.user = action.payload
    },
    isLoading(state: TRegistrationState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    isLoaded(state: TRegistrationState, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload
    },
    setRegistrationSuccess(state: TRegistrationState, action: PayloadAction<boolean>) {
      state.registrationSuccess = action.payload
    },
    setUserUnregistrated(state: TRegistrationState, action: PayloadAction<boolean>) {
      state.userUnregistrated = action.payload
    },
  },
})

export const { setUserToken, setUser, isLoading, setRegistrationSuccess, isLoaded } = registrationSlice.actions

export default registrationSlice.reducer
