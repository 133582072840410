import React, { useEffect } from 'react'
import { BaseLayout } from 'components/baseLayout'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { getProfile } from 'store/modules/profile/selectors'
import { TUser } from 'interfaces/model/User'

import { useHistory } from 'react-router-dom'

import { UserForm } from 'pages/profile/userForm'
import { SubcsriptionBadge } from 'pages/profile/subscription'

const Content = styled.section`
  display: flex;
  flex-direction: column;
  color: #3e4347;
  justify-content: space-between;
  width: 100%;
  height: 89vh;
  position: relative;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  ::webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`
const InputText = styled.section`
  padding: 0;
  font-size: 10px;
`

export const ProfilePage: React.FC = () => {
  const { register, control, errors, reset, handleSubmit } = useForm<TUser>()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(getProfile)

  useEffect(() => {
    if (user != null) {
      reset(user)
    }
  }, [user])

  return (
    <BaseLayout>
      <Content>
        <UserForm />

        <SubcsriptionBadge />
      </Content>
    </BaseLayout>
  )
}
