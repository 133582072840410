import React, { FC, useEffect } from 'react'
import { MenuItem, TMenuItem } from 'pages/home/components/menuItem'
import { UsersIcon } from 'components/icons/users'
// import { AvatarIcon } from 'components/icons/avatar'
import avatar from 'assets/icons/avatar.svg'
import { StatisticsIcon } from 'components/icons/statistics'
import { CatalogIcon } from 'components/icons/catalog'
import { PlayerIcon } from 'components/icons/player'
import { Routes } from 'routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store/store'
import { profileLoadRequest } from 'store/modules/profile/actions'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '17%',
    marginTop: 14,
    // breakpoints: theme.breakpoints.values,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    // '& div:first-child': {
    //   marginBottom: 48,
    // },
  },
}))

export const MenuSidebarItem: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(profileLoadRequest())
  }, [])
  const userName = useSelector((state: TState) => state.user?.data?.firstName)

  const sidebarItems: TMenuItem[] = [
    { title: userName, iconString: avatar, route: Routes.user },
    { title: 'Пользователи', iconSvg: UsersIcon, route: Routes.users },
    { title: 'Плееры', iconSvg: PlayerIcon, route: Routes.players },
    { title: 'Каталоги', iconSvg: CatalogIcon, route: Routes.catalogues },
    { title: 'Статистика', iconSvg: StatisticsIcon, route: Routes.statistics },
  ]
  return (
    <div className={classes.root}>
      {sidebarItems.map((item) => (
        <MenuItem key={item.title} item={item} />
      ))}
    </div>
  )
}
