import React, { FC, useState } from 'react'
import logo from 'assets/images/logo-min.svg'
import styled from 'styled-components'

export const HeaderBar = styled.div`
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  padding: 12px;
`
export const Logo = styled.div`
  padding: 0;
`
export const HeaderDesktop: FC = () => {
  const [open, setOpen] = useState(true)

  const onSetOpen = () => {
    setOpen((prev) => !prev)
  }

  return (
    <>
      <HeaderBar>
        <Logo>
          <img src={logo} alt="Логотип ОКС" />
        </Logo>
      </HeaderBar>
    </>
  )
}
