import React from 'react'
import { useSelector } from 'react-redux'
import { Routes } from 'routes/routes'
import { Route, Redirect } from 'react-router-dom'
import { getUser } from 'store/modules/auth/selectors'
import { FC } from 'react'

type TProps = {
  path: Routes | string
  exact?: boolean
}

export const PrivateRoute: FC<TProps> = ({ children, ...props }) => {
  const user = useSelector(getUser)
  const token = Boolean(localStorage.getItem('token'))
  return (
    <>
      <Route
        {...props}
        render={({ location }) =>
          token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: Routes.auth,
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  )
}
