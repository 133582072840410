import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MobileHeader } from 'pages/home/components/header'
import { makeStyles, Theme, createStyles, WithStyles } from '@material-ui/core/styles'
import { MenuSidebarItem } from 'pages/home/components/menuContainer'
import { HeaderDesktop } from 'pages/home/components/header/desktopHeader'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  display: flex;
  width: 100%;
`
export const Main = styled.div`
  padding: 24px 40px 30px 15px;
  width: 100%;
  overflow: auto;
  @media screen and (max-width: 1280px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 50px;
    padding: 24px 10px 30px 15px;
  }
`

const mobile = window.innerWidth < 500

const drawerWidth = mobile ? window.innerWidth : window.innerWidth * 0.4
//    TODO попробовать улучшить, чтоб ресайзилось при изменении размера динамично, по breakpoint брать размеры

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(10),
      padding: theme.spacing(5),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      padding: theme.spacing(0, 1),
    },
  }),
)

export const BaseLayout: React.FC = ({ children }) => {
  const classes = useStyles()
  const [width, setWidth] = useState(0)
  const [isMobile, setMobile] = useState(false)

  const isMobileWidth = () => setMobile(window.innerWidth <= 1280)

  useEffect(() => {
    isMobileWidth()
    const handleResize = () => {
      setWidth(window.innerWidth)
      isMobileWidth()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <Wrapper>
      {isMobile ? <MobileHeader /> : <HeaderDesktop />}

      <Content>
        {!isMobile && <MenuSidebarItem />}

        <Main>{children}</Main>
      </Content>
    </Wrapper>
  )
}
