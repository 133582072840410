import React, { FC, useState } from 'react'
import logo from 'assets/images/logo-min.svg'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import { AppBar, createStyles, makeStyles, Theme, useTheme } from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CssBaseline from '@material-ui/core/CssBaseline'
import CloseIcon from '@material-ui/icons/Close'
import { MenuSidebarItem } from 'pages/home/components/menuContainer'

const isMobile = window.innerWidth < 500
const drawerWidth = isMobile ? window.innerWidth : window.innerWidth * 0.4

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // TODO убрать createStyles
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },

    hide: {
      display: 'none',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    appBar: {
      backgroundColor: '#F2F2F2',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    drawerPaper: {
      width: drawerWidth,
    },
  }),
)

export const MobileHeader: FC = () => {
  const theme = useTheme()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <div>
            <img src={logo} alt="Логотип ОКС" />
          </div>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <CloseIcon color="primary" onClick={handleDrawerClose} />
        </div>
        <MenuSidebarItem />
      </Drawer>
    </div>
  )
}
