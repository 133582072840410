import React, { FC, useEffect } from 'react'
import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { TPlayerUser } from 'interfaces/model/PlayerUsers'
import useStyles from 'pages/users/styles'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Routes } from 'routes/routes'
import { deleteUser } from 'store/modules/playerUsers/actions'
import { loadSelectedUser } from 'store/modules/selectedPlayerUser/reducer'
import lockOpen from 'assets/icons/openLock.svg'
import bin from 'assets/icons/bin.svg'

type TProps = {
  item: TPlayerUser
}

/*eslint-disable*/

export const UsersCard: FC<TProps> = ({ item }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleDeleteUser = () => {
    dispatch(deleteUser(item.id))
  }
  const handleClick = (id: string | null) => {
    history.push(Routes.editUser + '/' + id)
    dispatch(loadSelectedUser(item))
  }

  return (
    <Card className={classes.root} variant="elevation" elevation={0}>
      <CardContent>
        <div className={classes.cardHeader}>
          {/*<LockIcon />*/}
          <CardActions>
            {/*<LockOpenIcon />*/}
            {/*<DeleteOutlineIcon onClick={handleDeleteUser} />*/}
            <img src={lockOpen} className={classes.openLock} alt="lockOpen" />
            <img src={bin} className={classes.bin} alt="bin" onClick={handleDeleteUser}/>
          </CardActions>
        </div>
        <CardActions className={classes.credentialsContainer} onClick={() => handleClick(item.id)}>
          <Typography className={classes.credentials}>{item.lastName}</Typography>
          <Typography className={classes.credentials}>{item.firstName}</Typography>
          <Typography className={classes.credentials}>{item.fatherName}</Typography>
          <Typography className={classes.email}>{item.email}</Typography>
        </CardActions>
      </CardContent>
    </Card>
  )
}
