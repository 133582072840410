import { request } from 'api'
import { RegistrationFormState } from 'pages/auth/registrationPage/index'
import { AppDispatch } from 'store/store'
import { isLoading, setRegistrationSuccess } from 'store/modules/registration/reducer'

export const registrationRequest = (data: RegistrationFormState) => {
  return (dispatch: AppDispatch) => {
    dispatch(isLoading(true))
    request
      .post('/users/clients/signup', { ...data, companyType: 'company' })
      .then(function (response) {
        const data = response.data.data
        localStorage.setItem('token', data.token)
        dispatch(isLoading(false))
        dispatch(setRegistrationSuccess(true))
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
          dispatch(isLoading(false))
        }
      })
  }
}
