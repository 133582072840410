import { request } from 'api'
import { AppDispatch } from 'store/store'
import { loadProfile, updateProfile } from './reducer'
import { TUpdateUser, TUser } from 'interfaces/model/User'

export const profileUpdateRequest = (data: TUser, id: string) => {
  return (dispatch: AppDispatch) => {
    const update: TUpdateUser = {
      ...data,
      ...data.company,
      // inn: data.company.inn
    }
    request
      .put(`/users/clients/${id}`, update)
      .then(function (response) {
        const data = response.data.data
        dispatch(updateProfile(data))
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
        }
      })
  }
}

export const profileLoadRequest = () => {
  return (dispatch: AppDispatch) => {
    request
      .get('/users/clients/me')
      .then(function (response) {
        const data = response.data.data
        dispatch(loadProfile(data))
      })
      .catch(function (err) {
        const errStatusCode = err.response.data.statusCode
        if (errStatusCode === 1001) {
        }
      })
  }
}
