import React, { FC, useEffect } from 'react'
import { BaseLayout } from 'components/baseLayout'
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { usersListRequest } from 'store/modules/playerUsers/actions'
import { TState } from 'store/store'
import { TPlayerUser } from 'interfaces/model/PlayerUsers'
import useStyles from 'pages/users/styles'
import { UsersCard } from './components/card'
import { NewCard } from 'pages/users/components/createCard'
import { useHistory } from 'react-router-dom'

export const UsersPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    ;(async () => {
      await dispatch(usersListRequest())
    })()
  }, [])
  const clients = useSelector((state: TState) => state.users.clients)
  const classes = useStyles()
/*eslint-disable*/
  return (
    <BaseLayout>
      <div className={classes.cardContent}>
        <div>
          <Typography className={classes.usersNumber}>{`Пользователей ${clients.length} из 5`} </Typography>
        </div>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <NewCard />
          </Grid>
          {clients.map((item: TPlayerUser) => {
            return (<Grid item xs={3} key={item.id}>
              <UsersCard item={item}/>
            </Grid>)
          })}
        </Grid>
      </div>
    </BaseLayout>
  )
}
