import React, { useEffect } from 'react'
import { PrimaryInput } from 'components/input'
import styled from 'styled-components'
import { AddAvatar } from 'pages/profile/avatar/avatar'
import { profileUpdateRequest, profileLoadRequest } from 'store/modules/profile/actions'
import { ButtonText, LogoutButton, SaveButton } from 'pages/auth/components/button'
import { useDispatch, useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { getProfile } from 'store/modules/profile/selectors'
import { TUpdateUser, TUser } from 'interfaces/model/User'
import { useHistory } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import Select from '@material-ui/core/Select'
import logout from 'assets/icons/logout.svg'
import { logoutRequest } from 'store/modules/auth/actions'
import { setAuthSuccess } from 'store/modules/auth/reducer'
import { Routes } from 'routes/routes'

const FieldsWrapper = styled.section`
  display: flex;
  @media (max-width: 1152px) {
    flex-wrap: wrap;
    > * {
      &:first-child {
        width: 40%;
      }
    }
    > * {
      &:not(:first-child) {
        width: 40%;
      }
    }
  }
  @media (max-width: 768px) {
    > * {
      &:first-child {
        width: 100%;
      }
    }
    > * {
      &:not(:first-child) {
        width: 100%;
      }
    }
  }
`
const ContainerWrapper = styled.section`
  display: flex;
  justify-content: space-between;
`

const TextFields = styled.section`
  display: flex;
  flex-direction: column;
  margin: 12px 10px;
  color: #3e4347;
  // @media (max-width: 1152px) {
  //   width: 40vw;
  // }
`
const InputText = styled.section`
  padding-left: 4px;
  font-size: 10px;
  line-height: 16px;
`

export const UserForm: React.FC = () => {
  const [companyType, setCompanyType] = React.useState('')
  const { register, control, errors, reset, handleSubmit } = useForm<TUser>()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(getProfile)

  const onSubmit = (data: TUser) => {
    if (user != null) {
      dispatch(profileUpdateRequest(data, user.id))
    }
  }
  const handleLogout = () => {
    dispatch(logoutRequest())
    dispatch(setAuthSuccess(false))
    history.push(Routes.auth)
  }

  useEffect(() => {
    dispatch(profileLoadRequest())
  }, [])

  useEffect(() => {
    if (user != null) {
      reset(user)
    }
  }, [user])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCompanyType(event.target.value as any)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ContainerWrapper>
        <AddAvatar />
        <LogoutButton onClick={handleLogout}>
          <img src={logout} />
          <ButtonText> Выйти из профиля </ButtonText>
        </LogoutButton>
      </ContainerWrapper>
      <FieldsWrapper>
        <TextFields>
          <InputText> Фамилия</InputText>
          <PrimaryInput name="lastName" type="text" defaultValue="" ref={register()} />
        </TextFields>
        <TextFields>
          <InputText> Имя </InputText>
          <PrimaryInput name="firstName" type="text" defaultValue="" ref={register()} />
        </TextFields>
        <TextFields>
          <InputText> Отчество</InputText>
          <PrimaryInput name="fatherName" type="text" defaultValue="" ref={register()} />
        </TextFields>
      </FieldsWrapper>
      <FieldsWrapper>
        <TextFields>
          <InputText> Номер телефона</InputText>
          <PrimaryInput name="phone" type="number" defaultValue="" ref={register()} />
        </TextFields>
        <TextFields>
          <InputText> Email</InputText>
          <PrimaryInput name="email" type="email" defaultValue="" ref={register()} />
        </TextFields>
      </FieldsWrapper>

      {/*<FieldsWrapper>*/}
      {/*  <TextFields>*/}
      {/*    <FormControl style={{ minWidth: 248 }}>*/}
      {/*      <InputLabel id="select-label">Субъект права</InputLabel>*/}

      {/*      <Controller*/}
      {/*        as={*/}
      {/*          <Select>*/}
      {/*            <MenuItem value=""></MenuItem>*/}
      {/*            <MenuItem value="company">Юридическое лицо</MenuItem>*/}
      {/*            <MenuItem value="person">Физическое лицо</MenuItem>*/}
      {/*          </Select>*/}
      {/*        }*/}
      {/*        name="companyType"*/}
      {/*        rules={{ required: 'Это обязательное поле' }}*/}
      {/*        control={control}*/}
      {/*        defaultValue=""*/}
      {/*      />*/}
      {/*      /!*<FormHelperText>{errors.wordlevel && errors.wordlevel.message}</FormHelperText>*!/*/}
      {/*    </FormControl>*/}
      {/*  </TextFields>*/}
      {/*</FieldsWrapper>*/}

      <FieldsWrapper>
        <TextFields>
          <InputText>Компания</InputText>
          <PrimaryInput name="company.name" type="text" defaultValue="" ref={register()} />
        </TextFields>
        <TextFields>
          <InputText>ИНН</InputText>
          <PrimaryInput name="company.inn" type="number" defaultValue="" ref={register()} />
        </TextFields>
      </FieldsWrapper>
      <FieldsWrapper>
        <TextFields>
          <InputText> Страна</InputText>
          <PrimaryInput name="country" type="text" defaultValue="" ref={register()} />
        </TextFields>
        <TextFields>
          <InputText> Область </InputText>
          <PrimaryInput name="region" type="text" defaultValue="" ref={register()} />
        </TextFields>
        <TextFields>
          <InputText>Город</InputText>
          <PrimaryInput name="city" type="text" defaultValue="" ref={register()} />
        </TextFields>
      </FieldsWrapper>

      <FieldsWrapper>
        <SaveButton type="submit">Сохранить</SaveButton>
      </FieldsWrapper>
    </form>
  )
}
