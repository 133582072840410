import React, { FC } from 'react'
import { TPhonogram, TPlaylist } from '../../../../store/modules/catalogs/types'
import {
  CardMedia,
  Checkbox,
  FormControlLabel,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core'
import Remove from '@material-ui/icons/Remove'
import { createStyles, makeStyles, rgbToHex, Theme, WithStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addIcon: {
      height: 20,
      width: 20,
      alignSelf: 'center',
    },
    table: {
      width: '100%',
    },
    circle: {
      background: '#456BD9',
      border: '0.1875em solid #0F1C3F',
      borderRadius: '50%',
      height: '1em',
      width: '1em',
    },
    profanity: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingLeft: 10,
      alignItems: 'center',
    },
    tables: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      height: 500,
    },
    tableCells: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#E0E0E0',
      paddingLeft: 8,
    },
    tableBorder: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#E0E0E0',
      paddingLeft: 8,
    },
  }),
)

type Order = 'asc' | 'desc'
interface HeadCell {
  disablePadding: boolean
  id: keyof TPhonogram
  label: string
  numeric: boolean
}
const headCells: HeadCell[] = [
  {
    id: 'catalogPhonogramNumber',
    numeric: true,
    disablePadding: false,
    label: 'Номер',
  },
  {
    id: 'genre',
    numeric: false,
    disablePadding: false,
    label: 'Жанр',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Название',
  },
  {
    id: 'singerName',
    numeric: false,
    disablePadding: false,
    label: 'Исполнитель',
  },
  {
    id: 'profanity',
    numeric: false,
    disablePadding: false,
    label: 'НН лексика',
  },
]

interface IProps {
  tracks: TPhonogram[]
  handleChoose: (track: TPhonogram) => void
  isCreated?: boolean
  withChoose?: boolean
}
export const CustomTable = ({ tracks, handleChoose, isCreated, withChoose }: IProps) => {
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TPhonogram) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChooseItem = (event: React.MouseEvent<unknown>, item: TPhonogram) => {
    handleChoose(item)
  }

  const createSortHandler = (property: keyof TPhonogram) => (event: React.MouseEvent<HTMLSpanElement>) => {
    handleRequestSort(event, property)
  }
  const classes = useStyles()
  const [selected, setSelected] = React.useState<string[]>([])
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof TPhonogram>('id')
  const isSelected = (id: string) => selected.indexOf(id) !== -1
  const desktop = window.innerWidth > 1400
  const cellsWidth = withChoose ? (desktop ? 170 : 100) : '100%'
  return (
    <TableContainer component={Paper} style={{ height: '100%', borderWidth: 1, borderStyle: 'solid', minHeight: 500 }}>
      <Table className={classes.table} aria-labelledby="tableTitle" size={'small'}>
        <TableHead className={classes.tableCells} style={{ maxWidth: cellsWidth }}>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
                style={{
                  maxWidth: headCell.id === 'genre' || headCell.id === 'catalogPhonogramNumber' ? 60 : cellsWidth,
                  overflow: 'hidden',
                }}
                className={classes.tableBorder}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.id === 'profanity' ? (withChoose ? '' : headCell.label) : headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            {withChoose && <TableCell className={classes.tableCells} />}
          </TableRow>
        </TableHead>
        <TableBody className={classes.table}>
          {tracks.map((track, index) => {
            const isItemSelected = isSelected(track.id)
            const labelId = `enhanced-table-checkbox-${index}`

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, track.id)}
                role="checkbox"
                tabIndex={-1}
                key={index}
                className={classes.tableCells}
              >
                <TableCell align="left" padding="none" className={classes.tableBorder} style={{ maxWidth: cellsWidth }}>
                  {track.catalogPhonogramNumber}
                </TableCell>
                <TableCell
                  align="left"
                  padding="none"
                  style={{ maxWidth: 60, overflow: 'hidden' }}
                  className={classes.tableBorder}
                >
                  {track.genre}
                </TableCell>
                <TableCell align="left" padding="none" className={classes.tableCells} style={{ maxWidth: cellsWidth }}>
                  {track.name}
                </TableCell>
                <TableCell align="left" padding="none" className={classes.tableCells} style={{ maxWidth: cellsWidth }}>
                  {track.singerName}
                </TableCell>
                <TableCell align="left" padding="none" style={{ paddingRight: 5 }} className={classes.tableBorder}>
                  <div className={classes.profanity}>
                    <div className={classes.circle} />
                    {track.profanity ? 'Да' : 'Нет'}
                  </div>
                </TableCell>
                {withChoose && (
                  <TableCell padding="none" className={classes.tableBorder}>
                    {isCreated ? (
                      <Remove
                        onClick={(event) => handleChooseItem(event, track)}
                        className={classes.addIcon}
                        style={{ fill: 'red' }}
                      />
                    ) : (
                      <Add
                        onClick={(event) => handleChooseItem(event, track)}
                        className={classes.addIcon}
                        style={{ fill: 'green' }}
                      />
                    )}
                  </TableCell>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
