if (!process.env.REACT_APP_BASE_URL) {
  throw new Error('Please specify REACT_APP_BASE_URL env variable')
}

export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  standardCover: 'https://storage.theoryandpractice.ru/tnp/uploads/image_unit/000/156/586/image/base_87716f252d.jpg',
}
