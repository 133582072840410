import React, { FC } from 'react'
import { Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: 288,
    maxWidth: 390,
    height: 240,
    backgroundColor: '#F8F9FB',
    marginRight: 16,
    marginTop: 16,
    borderRadius: 16,
    '&:hover': {
      boxShadow: '0px 2px 16px #CCD0D9',
    },
  },
  customBadge: {
    backgroundColor: '#27AE60',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '25%',
    alignItems: 'center',
    textAlign: 'center',
  },
  actionText: {
    fontSize: 18,
    marginTop: 11,
    lineHeight: '140%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#2164A9',
  },
}))

export const NewCard: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const handleClick = () => {
    history.push(Routes.newUser)
  }

  return (
    <Card className={classes.root} variant="elevation" elevation={0}>
      <CardContent>
        <CardActions disableSpacing className={classes.content}>
          <AddIcon color="primary" fontSize="small" onClick={handleClick} />
          <Typography color="primary" className={classes.actionText}>
            Добавить пользователя
          </Typography>
        </CardActions>
      </CardContent>
    </Card>
  )
}
