import React, { FC, ComponentType } from 'react'
import { GlobalStyle } from 'styles/global-styles'
import { NavLink, useLocation } from 'react-router-dom'
import { makeStyles, SvgIconProps } from '@material-ui/core'
import ListItemLink from '@material-ui/core/ListItem'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  icon: {
    marginRight: 10,
  },
  iconString: {
    marginTop: 3,
    marginRight: 10,
    marginLeft: -5,
    width: 38,
    height: 'auto',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: 230,
    height: 56,
    fontSize: 18,
    minWidth: 186,
    padding: 16,
    color: '#3e4347',
    background: '#f8f9fb',
    borderRadius: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  active: {
    background: 'linear-gradient(to left, #0084d1 0%, #4233b0 50%, #a902f6 100%)',
    color: '#f5f5f5',
  },
}))

export type TProps = {
  item: TMenuItem
}
export type TMenuItem = {
  title: any
  iconSvg?: ComponentType<SvgIconProps>
  iconString?: string
  // icon: any
  route: string
}
/*eslint-disable*/

export const MenuItem: FC<TProps> = ({ item }) => {
  const classes = useStyles()
  const location = useLocation()
  const active = location.pathname.split('/')[1] === item.route.split('/')[1]
  return (
    <>
      <GlobalStyle />
      {/*TODO убрать GlobalStyle*/}
      <ListItemLink component={NavLink} to={item.route} className={classes.root}>
        <div
            className={classNames(classes.textContainer, {
              [classes.active]: active,
            })}
        >
          {(item.iconSvg != null) ? (
              <item.iconSvg classes={{ root: classes.icon }} />
          ) : (
              <img className={classes.iconString} src={item.iconString} alt="Avatar" />
            )}
        <div>
          {item.title}
          <NavLink to={item.route} />
        </div>
        </div>
      </ListItemLink>
    </>
  )
}
