import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TPlayerUser, TUsersState } from 'interfaces/model/PlayerUsers'

const initialState: TUsersState = {
  admin: {} as TPlayerUser,
  clients: [] as TPlayerUser[],
  isLoading: false,
  isLoaded: false,
  isCreated: false,
  isDeletedSuccess: false,
}

const playerUserSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadUsers(state: TUsersState, action: PayloadAction<TPlayerUser[]>) {
      state.clients = action.payload
      state.isLoaded = true
      state.isLoaded = false
    },
    updatePlayerUsers(state: TUsersState, action: PayloadAction<TPlayerUser[]>) {
      state.clients = action.payload
      state.isLoaded = true
      state.isLoaded = false
    },
    addedPlayerUser(state: TUsersState) {
      state.isCreated = true
    },
    deletePlayerUserSuccess(state: TUsersState, action: PayloadAction<string | null>) {
      state.clients = state.clients.filter((id) => id.id !== action.payload)
      // state.isDeletedSuccess = action.payload
    },
  },
})

export const { loadUsers, updatePlayerUsers, addedPlayerUser, deletePlayerUserSuccess } = playerUserSlice.actions

export default playerUserSlice.reducer
