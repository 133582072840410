import { createGlobalStyle } from 'styled-components'
type TextProps = {
  whiteColor?: boolean
  fontSize?: string
}
export const GlobalStyle = createGlobalStyle`
*,
*:after,
*:before {
  box-sizing:border-box;
}
body { 
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize};
  font-family: 'Roboto', sans-serif;
  color: ${(props: TextProps) => (props.whiteColor ? '#f5f5f5' : '#3E4347')};
  background: #FFFFFF;
  overflow-y: hidden;
}
button {
    color: #f5f5f5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
`
// export default GlobalStyle
