import React, { FC, useEffect } from 'react'
import { Input, InputContainer } from 'components/input'
import { ThemeProvider } from 'styled-components'
import logo from 'assets/images/logo.svg'
import { useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { Button } from 'pages/auth/components/button'
import { Container, OutlineContainer, SubTitle, Wrapper } from 'pages/auth/components/container'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { authRequest } from 'store/modules/auth/actions'
import { getIsLoaded, getAuthSuccess, getIsLoading, getUser } from 'store/modules/auth/selectors'
import { GlobalStyle } from 'styles/global-styles'
import { StyledArrowLink, styles } from 'pages/auth/styles'
import { userSchema } from 'constants/ValidationMessage'
import { mainTheme } from 'styles/main-theme'
import Arrow from 'assets/icons/arrow.svg'

export type RestorePassFormState = {
  email: string
  password: string
  isLoading: false
  type: string
}

export const RestorePasswordPage: FC = () => {
  const { register, errors, handleSubmit } = useForm<RestorePassFormState>({
    resolver: yupResolver(userSchema),
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const userIsLogin = useSelector(getAuthSuccess)
  const user = useSelector(getUser)
  const loaded = useSelector(getIsLoaded)
  const loading = useSelector(getIsLoading)

  const onSubmit = (data: RestorePassFormState) => {
    dispatch(authRequest(data))
  }

  useEffect(() => {
    console.log('authPage useEffect')
    if (userIsLogin) {
      history.push(Routes.user)
    }
  }, [userIsLogin])

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle whiteColor />
      <Wrapper>
        <OutlineContainer>
          <Container>
            <div>
              <img style={styles.Logo} src={logo} alt="караоке" />
            </div>
            <div style={styles.Title}> Объединённые караоке системы </div>
            <SubTitle> Восстановление пароля </SubTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
              <InputContainer>
                <Input primaryText name="email" placeholder="Email" defaultValue="test@test.com" ref={register()} />
              </InputContainer>
              <p>{errors.email?.message}</p>

              {/* TODO добавить валидацию {errors.email?.type === 'required' && <p>Обязательное поле</p>}*/}
              {/*{errors.email?.type === 'minLength' && <p>Введите email </p>}*/}

              <Button primary disabled={false}>
                Отправить
              </Button>
              <StyledArrowLink as="a" href={Routes.auth}>
                <div>
                  <img src={Arrow} />
                </div>
                <div>Назад</div>
              </StyledArrowLink>
            </form>
          </Container>
        </OutlineContainer>
      </Wrapper>
    </ThemeProvider>
  )
}
