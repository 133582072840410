import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    fill: 'none',
    stroke: 'currentColor',
    strokeWidth: 2,
  },
})

/* eslint-disable */
export const StatisticsIcon: React.FC<SvgIconProps> = ({ className, ...otherProps }) => {
  const styles = useStyles()


  return (
    <SvgIcon className={classNames(styles.root, className)} viewBox="0 0 32 32" {...otherProps}>
      <path
        d="M1 12H23M12 1L12 23M7 3V17M17 21V6M2 5V14M22 19V10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}
