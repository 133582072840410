import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TUser } from 'interfaces/model/User'

const initialState: TUserState = {
  data: null,
  isLoading: false,
  isLoaded: false,
}

export type TUserState = {
  isLoading: boolean
  isLoaded: boolean
  data: TUser | null
}

const userEditSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadProfile(state: TUserState, action: PayloadAction<TUser>) {
      state.data = action.payload
      state.isLoaded = true
      state.isLoaded = false
    },
    updateProfile(state: TUserState, action: PayloadAction<TUser>) {
      state.data = action.payload
      state.isLoaded = true
      state.isLoaded = false
    },
  },
})

export const { loadProfile, updateProfile } = userEditSlice.actions

export default userEditSlice.reducer
