import React from 'react'
import { RootRoute } from 'routes'
import { Provider } from 'react-redux'
import { store } from 'store/store'
import { ThemeProvider } from 'styled-components'
import { mainTheme } from 'styles/main-theme'

export function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <Provider store={store}>
        <RootRoute />
      </Provider>
    </ThemeProvider>
  )
}
