import React, { FC } from 'react'
import styled from 'styled-components'
import editIcon from 'assets/icons/edit.svg'

export const SubscriptionContainer = styled.div`
  width: 390px;
  height: 240px;
  margin: 15px;
  position: relative;
  background: #f8f9fb;
  border-radius: 23px;
  align-items: center;

  &: before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-image: linear-gradient(to top, #0084d1 0%, #4233b0 50%, #a902f6 100%);
    border-radius: 24px;
    z-index: -1;
  }
  @media (max-width: 1152px) {
    width: 360px;
  }
  @media (max-width: 367px) {
    width: 283px;
  }
`
export const Content = styled.div`
  padding: 16px;
  > * {
    &:first-child {
      margin-bottom: 30px;
    }
  }
  > * {
    &:nth-child(5) {
      margin-top: 40px;
    }
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Title = styled.div`
  color: linear-gradient(#0084d1 11.34%, #a902f6 100%);
`
export const Tags = styled.div`
  color: #3e4347;
  font-weight: bold;
`
export const SubcsriptionBadge: FC = () => {
  return (
    <div>
      <SubscriptionContainer>
        <Content>
          <Row>
            <Title>Максимум</Title>
            <img src={editIcon} />
          </Row>

          <Tags>16 устройств</Tags>
          <Tags>Все трэки</Tags>
          <Tags>Техподдержка 365/24/7</Tags>

          <div>Действует до 20.10.21</div>
        </Content>
      </SubscriptionContainer>
    </div>
  )
}
