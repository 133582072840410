import styled from 'styled-components'

type InputProps = {
  primaryBackground?: boolean
  primaryText?: boolean
  primaryPlaceholder?: boolean
}
export const InputContainer = styled.div`
  margin: 20px;
  width: 25vw;
  height: 63px;
  border-radius: 8px;
  overflow: hidden;
  padding: 1px;
  margin-top: 24px;
  background-image: linear-gradient(to left, #0084d1 0%, #4233b0 50%, #a902f6 100%);
  margin-top: 16px;
  @media (max-width: 1152px) {
    width: 39vw;
  }
`

export const Input = styled.input`
  position: relative;
   {
    /* background: ${(props: InputProps) =>
      props.primaryBackground ? 'linear-gradient(to right, #0084d1 0%, #4233b0 50%, #a902f6 100%)' : 'transparent'}; */
  }
  background: #040826;
  color: ${(props: InputProps) => (props.primaryText ? '#F5F5F5' : '#3E4347')};
  width: 100%;
  height: 100%;
  border: 1px solid #040826;
  border-radius: 8px;
  text-align: center;
  position: relative;
  outline: none;
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  @media (max-width: 1152px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
`

export const PrimaryInput = styled(Input)`
  background-color: #ffffff;
  height: 32px;
  padding-left: 8px;
  text-align: left;
  min-width: 248px;
  max-width: 15vw;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 16px;
  @media (max-width: 1152px) {
    max-width: 40vw;
  }
  @media (max-width: 768px) {
    max-width: 85vw;
  }
`
