import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    stroke: 'currentColor',
    strokeWidth: 2,
    fill: 'none',
  },
})
/* eslint-disable */
export const CatalogIcon: React.FC<SvgIconProps> = ({ className, ...otherProps }) => {
  const styles = useStyles()

  return (
    <SvgIcon className={classNames(styles.root, className)} {...otherProps}>
      <path d="M4 5.00001V2.00002C4 1.44773 4.44771 1.00002 5 1.00002L10 1.00002C10.5 1.00002 11 1.44773 11 2.00002V4.00002L22 4.00001C22.5 4.00001 23 4.50001 23 5.00001V16C23 16.5523 22.5523 17 22 17L20 17M2 5.00002C1.44771 5.00002 1 5.44773 1 6.00002V20C1 20.5523 1.44772 21 2 21L19 21C19.5523 21 20 20.5523 20 20V9.00001C20 8.50001 19.5 8.00001 19 8.00001L8 8.00002C8 8.00002 8 7.00002 8 6.00002C8 5.44773 7.5 5.00002 7 5.00002C5.00003 4.99999 2.00008 5.00002 2 5.00002ZM2 5.00002C2 5.00002 1.99999 5.00002 2 5.00002Z"/>

    </SvgIcon>
  )
}
