import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    stroke: 'currentColor',
    strokeWidth: 2,
    fill: 'none',
  },
})
/* eslint-disable */
export const PlayerIcon: React.FC<SvgIconProps> = ({ className, ...otherProps }) => {
  const styles = useStyles()

  return (
    <SvgIcon className={classNames(styles.root, className)} {...otherProps}>
      <path d="M1 3C1 1.89543 1.89543 1 3 1H21C22.1046 1 23 1.89543 23 3V17C23 18.1046 22.1046 19 21 19H3C1.89543 19 1 18.1046 1 17V3Z"/>
      <path d="M8 13.3005V6.69951C8 5.93703 8.81911 5.45506 9.48564 5.82536L15.4265 9.12584C16.1123 9.50685 16.1123 10.4932 15.4265 10.8742L9.48564 14.1746C8.81911 14.5449 8 14.063 8 13.3005Z" />
    </SvgIcon>
  )
}
