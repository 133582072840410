import React, { FC } from 'react'
import { BaseLayout } from 'components/baseLayout'
import { Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import useStyles from 'pages/users/userCreate/styles'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store/store'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Routes } from 'routes/routes'
import AddIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { useHistory } from 'react-router'
import TextFieldInput from 'components/textFieldInput'
import { TDevice } from 'interfaces/model/Devices'
import { editDevice } from 'store/modules/selectedDevice/actions'

type TProps = {
  device?: TDevice
}

export const DeviceEditPage: FC<TProps> = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { register, control, errors, reset, handleSubmit } = useForm<TDevice>()
  const devices = useSelector((state: TState) => state.selectedDevice)

  const onSubmit = (data: TDevice) => {
    if (devices.data !== null) {
      dispatch(editDevice(devices.data.id, data, redirectToDevices))
    }
  }

  const history = useHistory()
  const redirectToDevices = () => {
    history.push(Routes.players)
  }
  return (
    <BaseLayout>
      <Grid container>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid container className={classes.header}>
            <Grid item>
              <ArrowBackIcon color="primary" onClick={redirectToDevices} />
            </Grid>
            <Grid item className={classes.button}>
              <input type="submit" placeholder="Сохранить" />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid container item xs={3}>
              <Controller
                field={{ title: 'Название плеера', maxWidth: 248 }}
                as={TextFieldInput}
                name="name"
                defaultValue={devices?.data?.name}
                control={control}
              />
            </Grid>
            <Grid container item xs={3}>
              <Controller
                as={TextFieldInput}
                field={{ title: 'Устройство', maxWidth: 248 }}
                name="deviceInfo"
                defaultValue={devices?.data?.deviceInfo}
                control={control}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </BaseLayout>
  )
}
