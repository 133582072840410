import React, { FC } from 'react'
import { BaseLayout } from 'components/baseLayout'

export const StatisticsPage: FC = () => {
  return (
    <BaseLayout>
      <div> Statistics</div>
    </BaseLayout>
  )
}
