import React, { FC, useEffect } from 'react'
import { Input, InputContainer } from 'components/input'
import { ThemeProvider } from 'styled-components'
import logo from 'assets/images/logo.svg'
import { Link, useHistory } from 'react-router-dom'
import { Routes } from 'routes/routes'
import { Button, Span } from 'pages/auth/components/button'
import { OutlineSmallContainer, SmallContainer, SubTitle, Wrapper } from 'pages/auth/components/container'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { authRequest } from 'store/modules/auth/actions'
import { getIsLoaded, getAuthSuccess, getIsLoading, getUser } from 'store/modules/auth/selectors'
import { GlobalStyle } from 'styles/global-styles'
import { StyledLink, styles } from 'pages/auth/styles'
import { userSchema } from 'constants/ValidationMessage'
import { mainTheme } from 'styles/main-theme'

export type LoginFormState = {
  email: string
  password: string
  isLoading: false
  type: string
}

export const LoginForm: FC = () => {
  const { register, errors, handleSubmit } = useForm<LoginFormState>({
    resolver: yupResolver(userSchema),
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const userIsLogin = useSelector(getAuthSuccess)

  const onSubmit = (data: LoginFormState) => {
    dispatch(authRequest(data))
  }

  const user = useSelector(getUser)
  const loaded = useSelector(getIsLoaded)
  const loading = useSelector(getIsLoading)

  useEffect(() => {
    if (userIsLogin) {
      history.push(Routes.user)
    }
  }, [userIsLogin])

  // useEffect(() => {
  //   if (errors) {
  //     Object.values(errors).map(() => toast("error....");)
  //   }
  // }, [errors])

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle whiteColor />
      <Wrapper>
        <OutlineSmallContainer>
          <SmallContainer>
            <div>
              <img style={styles.Logo} src={logo} alt="караоке" />
            </div>
            <div style={styles.Title}> Объединённые караоке системы </div>
            <SubTitle> Авторизация </SubTitle>

            <form onSubmit={handleSubmit(onSubmit)}>
              <InputContainer>
                <Input primaryText name="email" placeholder="Email" defaultValue="test-lk@mail.ru" ref={register()} />
              </InputContainer>
              <p>{errors.email?.message}</p>
              {/*{errors.email?.type === 'required' && <p>Обязательное поле</p>}*/}
              {/*{errors.email?.type === 'minLength' && <p>Введите email </p>}*/}
              <InputContainer>
                <Input
                  primaryText
                  name="password"
                  type="password"
                  placeholder="Пароль"
                  defaultValue="12312312"
                  ref={register}
                />
              </InputContainer>
              <p>{errors.password?.message}</p>
              <div>
                <StyledLink as="a" href={Routes.restorePassword}>
                  Восстановить пароль
                </StyledLink>
              </div>
              <Button primary disabled={false}>
                Войти
              </Button>
            </form>

            <StyledLink as="a" href={Routes.signup}>
              или <span>Зарегистрироваться</span>
            </StyledLink>
          </SmallContainer>
        </OutlineSmallContainer>
      </Wrapper>
    </ThemeProvider>
  )
}
